<template>
  <div class="page-stores-index">
    <back/>
    <div class="header">
      <n-loader :loading="($var('load'))"></n-loader>
      <div class="avatar">
        <img :src="store.avatar" alt="">
      </div>
      <div class="container">
        <h2 class="title">{{ store.title }}</h2>
        <div class="reviews">
          <div>
            <star-rating :read-only="true" :star-size="15" :rating="store.rating" :increment="0.01"></star-rating>
          </div>
          <n-link class="show-reviews" :to="{name: 'stores.reviews', params:{id: store.id},}">
            Смотреть отзывы ({{ store.reviewsCount }})
          </n-link>
        </div>
        <div class="sub">В нашем магазине с {{ $app.date.format(store.createdAt, 'date') }}</div>
        <div class="info-wrap">
          <div class="item">
            <div class="sub">Адрес:</div>
            <div>{{ store.address }}</div>
          </div>
          <div class="item">
            <div class="sub">Телефон:</div>
            <div>{{ $n.phoneFormat(store.phone) }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="category">
      <div class="container">
        <div v-if="category.parent" class="title">{{ category.parent.title }}</div>
        <h2 v-if="category.title">{{ category.title }}</h2>
        <h2 v-else-if="!$var('loadCategory')">Все категории</h2>

        <n-link class="select-category" :to="{ name: 'categories.list', query: { store: store.id, }, }">
          Выбрать категорию
        </n-link>
      </div>
    </div>

    <div class="container">
      <sort :is-active.sync="isActive" @filter="loadProducts" />
    </div>

    <div class="container">
      <h3>Топ товаров</h3>
    </div>
    <c-products-list-top :data="topProducts" :loading="$var('loadTop')"/>

    <div class="container">
      <h3>Витрина товаров</h3>
    </div>
    <div class="container">
      <c-products-list :data="products" :loading="$var('loadProducts')" :list="!isActive"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageStoresIndex',
  data() {
    return {
      isActive: true,
      topProducts: [],
      products: [],
      store: {},
      category: {},
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    categoryId() {
      return this.$route.query.categoryId
    },
  },
  created() {
    this.load()
    this.loadCategory()
    this.loadTopProducts()
    this.loadProducts()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.stores.get(this.id).then((response) => {
        this.store = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    list() {
      this.isActive = false
    },
    tile() {
      this.isActive = true
    },
    loadCategory() {
      this.$var('loadCategory', true)
      $api.app.categories.get(this.categoryId).with('parent').then((response) => {
        this.category = response.data.content
      }).finally(() => {
        this.$var('loadCategory', false)
      })
    },
    loadTopProducts() {
      this.$var('loadTopProducts', true)
      $api.app.products.get().filter({ storeId: this.id, }).sort('salesCount', 'desc').then((response) => {
        this.topProducts = response.data.content
      }).finally(() => {
        this.$var('loadTopProducts', false)
      })
    },
    loadProducts(filter = (v) => v) {
      this.$var('loadProducts', true)
      filter($api.app.products.get()).filter({ storeId: this.id, }).then((response) => {
        this.products = response.data.content
      }).finally(() => {
        this.$var('loadProducts', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-stores-index {
  .header {
    position: relative;
    margin-bottom: 30px;

    .info-wrap {
      padding-top: 15px;
      margin-top: 15px;
      border-top: 1px solid #EBEBEB;

      .item {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #404040;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .sub {
          width: 70px;
        }
      }
    }
  }

  .avatar {
    max-height: 290px;
    min-height: 240px;
    margin-bottom: 13px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }

  .title {
    margin-bottom: 4px;
  }

  .sub {
    font-size: 12px;
    color: #808080;
  }

  .reviews {
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .show-reviews {
      text-decoration-line: underline;
      margin-left: 14px;
      color: #BABABA;
    }
  }

  .category {
    padding: 25px 0 15px;
    position: relative;

    .title {
      font-size: 12px;
      color: #808080;
    }

    .select-category {
      font-size: 12px;
      text-decoration-line: underline;
      color: #2A82D3;
      display: inline-block;
      margin-bottom: 15px;
    }
  }

  .sort-and-search {
    margin-bottom: 25px;
  }

  .search-wrap {
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    height: 47px;
    padding: 0 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


    path {
      fill: #BABABA !important;
    }

    input {
      width: 100%;
      border: none;
      padding-left: 10px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-size: 12px;
        padding: 3px 0 5px;
        color: #BABABA;
      }
    }
  }

  .content-view {
    display: flex;
    align-items: center;

    .icon-wrap {
      width: 80px;
      text-align: right;

      svg {
        &.active {

        }

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
